import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import httpWithLogging from 'universal/http-client';
import classnames from 'classnames';
import alertSvg from 'assets/images/Alert.svg';
import './MyCommunicationPrefPage.scss';
import { defaultApiTimeout } from '../../../bg360/constants';
import Toast from './Toast';

function CommunicationPrefPage({
  emailID,
  phoneNumber,
  getUserData,
  openMobileNav,
  communicationPrefTwoToggle,
}) {
  const [email, setEmail] = useState(false);
  const [text, setText] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [showTextToast, setShowTextToast] = useState(false);
  const [isSpecialcase, setSpecialCase] = useState(false);
  const [showDoubleOptinMessage, setShowDoubleOptinMessagge] = useState(false);
  const [initialFieldState, setInitialFieldState] = useState({ email, text });

  const commonToast = 'Your changes have been saved';
  const textToast = `Success! We've sent you a text message. To confirm your request, please respond with a "Y"`;

  const preserveInitialFieldState = field => {
    setInitialFieldState({
      ...initialFieldState,
      ...{ [field.field]: field.value },
    });
  };

  const shouldShowToast = field => {
    const current = field === 'email' ? email : text;
    const status = initialFieldState[field] !== current;

    return status;
  };

  const handleEmailApi = async () => {
    try {
      const requestApi = httpWithLogging({}, defaultApiTimeout, true);
      const userData = await getUserData();
      const headers = {
        Authorization: `Bearer ${userData.idToken}`,
        'Content-Type': 'application/json',
      };

      const payload = {
        email: emailID,
      };
      if (communicationPrefTwoToggle && phoneNumber) {
        payload['phone'] = phoneNumber;
      }
      const resp = await requestApi.post(
        `${NMConfig.API_COMM_PREF}/communication/${userData.ucaId}/preferences/fetch`,
        payload,
        { headers },
      );
      const emailOptout = resp?.data?.preferences?.marketing?.email?.optout;
      const phoneOptout = resp?.data.preferences?.marketing?.text?.optout;
      const doubleOptin = resp?.data.preferences?.marketing?.text?.doubleOptin;
      const contactPrivacy = resp?.data?.preferences?.marketing?.contactPrivacy;
      // for first time response in empty object
      if (typeof emailOptout === 'boolean') {
        setEmail(!emailOptout);
        setSpecialCase(contactPrivacy);
        preserveInitialFieldState({ field: 'email', value: !emailOptout });
      } else {
        setEmail(false);
        setSpecialCase(contactPrivacy);
        preserveInitialFieldState({ field: 'email', value: false });
      }
      if (!communicationPrefTwoToggle) {
        return;
      }
      if (typeof phoneOptout === 'boolean') {
        if (doubleOptin?.status === 'unsubscribed') {
          setText(false);
          preserveInitialFieldState({ field: 'text', value: false });
        }
        if (doubleOptin?.status === 'approved') {
          setText(true);
          preserveInitialFieldState({ field: 'text', value: true });
        }
        if (doubleOptin?.status === 'pending') {
          const givenDate = new Date(doubleOptin?.lastEvent); // Given date
          const now = new Date();
          const timeDifference = Math.abs(givenDate - now);
          // Check if the difference is within two hours (per req)
          const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
          if (timeDifference <= twoHoursInMilliseconds) {
            setText(true);
            setShowDoubleOptinMessagge(true);
            preserveInitialFieldState({ field: 'text', value: true });
          } else {
            setText(false);
            setShowDoubleOptinMessagge(false);
            preserveInitialFieldState({ field: 'text', value: false });
          }
        }
      }
    } catch (error) {
      setEmail(false);
      console.debug('Somethng went wrong', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleEmailApi();
  }, []);

  const handlSubmit = async () => {
    try {
      const requestApi = httpWithLogging({}, defaultApiTimeout, true);
      const userData = await getUserData().then(userData => userData);
      const headers = {
        Authorization: `Bearer ${userData.idToken}`,
        'Content-Type': 'application/json',
      };
      const dt = new Date();
      const preferences = {
        marketing: { email: { optout: !email }, text: { optout: !text } },
      };

      const payload = {
        email: emailID,
        ucaId: userData.ucaId,
        preferences: {
          ...preferences,
        },
        channel: 'ONLINE',
        updatedTime: dt.toISOString(),
        source: 'Account:Communication Preferences',
      };

      if (communicationPrefTwoToggle) {
        payload['phone'] = phoneNumber;
      }
      await requestApi.put(
        `${NMConfig.API_COMM_PREF}/communication/${userData.ucaId}/preferences`,
        payload,
        { headers },
      );
      setShowToast(shouldShowToast('email'));
      setShowTextToast(shouldShowToast('text'));
      setInitialFieldState({ email, text });
    } catch (error) {
      setEmail(false);
      console.debug('Somethng went wrong', error);
    }
  };
  return (
    <div className="Comm-pref-container">
      {!isloading && (
        <>
          <div className="comm_header not-mobile">
            COMMUNICATION PREFERENCES
          </div>
          {showToast && (
            <Toast message={commonToast} onClose={() => setShowToast(false)} />
          )}
          {showTextToast && (
            <Toast
              fullWidth={text}
              message={text ? textToast : commonToast}
              onClose={() => setShowTextToast(false)}
            />
          )}
          <div className="my-account-mobile-back-header mobile-only">
            <div
              className="my-account-mobile-back-header__back-btn"
              role="button"
              aria-label="Back"
              onClick={openMobileNav}
              onKeyPress={openMobileNav}
              tabIndex="0"
            >
              <i className="my-account-mobile-back-header__back-btn-icon" />
            </div>
            <h1 className="my-account-mobile-back-header__text">
              COMMUNICATION PREFERENCES
            </h1>
          </div>
          {isSpecialcase && (
            <div className="comm_text_dark">
              Your profile requires special handling, please contact our{' '}
              <a href="mailto:Privacy@NeimanMarcus.com">Privacy Department.</a>
            </div>
          )}
          <div
            className={classnames(
              'comm_text',
              isSpecialcase && 'comm_text_disabled',
            )}
          >
            Select your preferred communication method to stay up-to-date with
            marketing communications about products, services and promotions.
            You can manage your email and phone number in your{' '}
            {isSpecialcase ? (
              <div className="link-disabled">Account Overview.</div>
            ) : (
              <Link to="/e/myaccount/accountdetails">Account Overview.</Link>
            )}
          </div>
          <label
            className={classnames(
              'comm-checkbox-wrapper',
              isSpecialcase && 'comm-checkbox-wrapper-disabled',
            )}
            htmlFor="email"
          >
            <div className="checkbox-emulator">
              <label
                htmlFor="email"
                className="Comm-pref-container__kmsi-checkbox"
              >
                <input
                  disabled={isSpecialcase}
                  id="email"
                  type="checkbox"
                  className="Comm-pref-container__kmsi-checkbox-input"
                  checked={email}
                  onChange={e => setEmail(e.target.checked)}
                />
              </label>
            </div>
            <span>Email</span>
          </label>
          {communicationPrefTwoToggle && (
            <label
              className={classnames(
                'comm-checkbox-wrapper',
                isSpecialcase && 'comm-checkbox-wrapper-disabled',
              )}
              htmlFor="text"
            >
              <div className="checkbox-emulator">
                <label
                  htmlFor="text"
                  className="Comm-pref-container__kmsi-checkbox"
                >
                  <input
                    disabled={isSpecialcase}
                    id="text"
                    type="checkbox"
                    className="Comm-pref-container__kmsi-checkbox-input"
                    checked={text}
                    onChange={e => setText(e.target.checked)}
                    aria-label="Phone Number"
                  />
                </label>
              </div>
              <span>Text</span>
            </label>
          )}
          {communicationPrefTwoToggle && showDoubleOptinMessage && (
            <div className="double-optin-label">
              <div className="double-optin-icon">
                <img src={alertSvg} alt="info Icon" />
              </div>
              We’ve sent you a text message. To confirm your request, please
              respond with a “Y”
            </div>
          )}
          <div className="btn-container">
            <button
              className={classnames(
                'btn-save',
                isSpecialcase && 'btn-save-disabled',
              )}
              type="submit"
              onClick={handlSubmit}
              disabled={isSpecialcase}
            >
              Save Changes
            </button>
            <div
              className={classnames(
                'comm_text sa-content',
                isSpecialcase && 'comm_text sa-content-disabled',
              )}
            >
              Personalized communications from sales associates are managed
              independently. To unsubscribe from these messages, please reach
              out to your sales associate directly.
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CommunicationPrefPage;
