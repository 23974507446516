import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
// import includes from 'lodash/includes';
import map from 'lodash/map';
import join from 'lodash/join';
import result from 'lodash/result';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import reactCookie from 'react-cookie';
import httpWithLogging, {
  X_ABTEST_INFO,
  X_DEVICE_TYPE,
  httpWithLoggingWithoutCustomHeaders,
} from 'universal/http-client';
import { updatePlpGrsUtagData } from 'client/middleware/analytics/utagData';
import { replaceSpaceWithHyphenAndEncode } from 'client-utils/utilities-router';
import logger from 'server/utilities/logger';
import { stringify } from 'query-string';
import { buildCookieString } from 'universal/utilities-cookies';
import {
  MY_FAVORITES,
  FAVORITES_NOT_AVAILABLE_MESSAGE,
  LSSTemplateType,
  LSSPageName,
  EditorialTemplateType,
  EditorialPageName,
  MagzineTemplateType,
  MagzinePageName,
  // IN_STORE,
  // INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
} from 'plp/constants';
import {
  SET_CANONICAL_URL,
  getABTestAssignments,
  getABTests,
  PLP_PCS_RECOMMEDATION_MBOX_ID,
  FACETED_LEFT_NAV_MBOX_ID,
  FACETED_LEFT_NAV_TEST,
  PLP_PCS_RECOMMEDATION_TEST,
  SET_TEST_FROM_COOKIE,
} from 'shared/actions/actions-page';
import {
  showSpinner,
  hideSpinner,
} from 'shared/components/Spinner/spinner-actions';
import { openModal } from 'shared/components/Modal/actions';
import { LOADING_PRODUCT } from 'pdp/components/ProductPage/actions';
// import { isEmptyInStoreFilterValue } from '../ProductListPage/components/ProductList/components/Filters/components/InStoreFilter/inStoreFilter'; // 'plpFilters/components/InStoreFilter/inStoreFilter';

export const LOADING_PRODUCT_LIST = 'LOADING_PRODUCT_LIST';
export const LOADING_PRODUCT_LIST_METADATA = 'LOADING_PRODUCT_LIST_METADATA';
export const RESOLVED_PRODUCT_LIST = 'RESOLVED_PRODUCT_LIST';
export const REJECTED_PRODUCT_LIST = 'REJECTED_PRODUCT_LIST';
export const RESOLVED_PRODUCT_LIST_METADATA = 'RESOLVED_PRODUCT_LIST_METADATA';
export const RESOLVED_FAVORITE = 'RESOLVED_FAVORITE';

export const RESOLVED_CATEGORY_NO_RESULTS_CONTENT =
  'RESOLVED_CATEGORY_NO_RESULTS_CONTENT';
export const REJECTED_CATEGORY_NO_RESULTS_CONTENT =
  'REJECTED_CATEGORY_NO_RESULTS_CONTENT';
export const SET_PRODUCT_LIST_DATA_TO_UTAG = 'SET_PRODUCT_LIST_DATA_TO_UTAG';
export const ADD_RECENT_SIZES_TO_UTAG_DATA = 'ADD_RECENT_SIZES_TO_UTAG_DATA';
export const SET_FAVORITE_UTAG_DATA = 'SET_FAVORITE_UTAG_DATA';
export const INVALID_CATEGORY = 'INVALID_CATEGORY';
export const VALID_CATEGORY = 'VALID_CATEGORY';

export const SOURCE_QUICK_LOOK = 'SOURCE_QUICK_LOOK';
export const RESET_QUICK_LOOK_PRODUCT = 'RESET_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT = 'RESOLVED_QUICK_LOOK_PRODUCT';
export const NO_RESULTS_FOR_FILTER = 'NO_RESULTS_FOR_FILTER';

export const SET_INSTORE_FILTER_ERROR = 'SET_INSTORE_FILTER_ERROR';
export const RESET_INSTORE_FILTER_ERROR = 'RESET_INSTORE_FILTER_ERROR';
export const NO_RESULTS_FOR_FACET_FILTER = 'NO_RESULTS_FOR_FACET_FILTER';
export const INIT_SWATCH_VALUES = 'INIT_SWATCH_VALUES';
export const UPDATE_SWATCH_VALUE = 'UPDATE_SWATCH_VALUE';
export const SET_PLP_CATEGORY_ID = 'SET_PLP_CATEGORY_ID';

export const GET_VIEW_BY = 'GET_VIEW_BY';
export const LOADING_PLP_RELATED_CATS = 'LOADING_PLP_RELATED_CATS';
export const RESOLVED_PLP_RELATED_CATS = 'RESOLVED_PLP_RELATED_CATS';
export const REJECTED_PLP_RELATED_CATS = 'REJECTED_PLP_RELATED_CATS';

export const LOADING_PLP_BROUGHT_CATS = 'LOADING_PLP_BROUGHT_CATS';
export const RESOLVED_PLP_BROUGHT_CATS = 'RESOLVED_PLP_BROUGHT_CATS';
export const REJECTED_PLP_BROUGHT_CATS = 'REJECTED_PLP_BROUGHT_CATS';

export const SET_MOBILE_FILTER_SORT_BUTTON_FLAG =
  'SET_MOBILE_FILTER_SORT_BUTTON_FLAG';

export const SET_SEO_FACETS_ORDER = 'SET_SEO_FACETS_ORDER';
export const SET_SEO_FACETS_MAP = 'SET_SEO_FACETS_MAP';

export const types = {
  SET_CATEGORY: 'SET_CATEGORY',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  GET_IMAGE: 'GET_IMAGE',
  IMAGE_LOAD_ERROR: 'IMAGE_LOAD_ERROR',
};

export function addRecentSizesToUtagData(recentSizes) {
  return dispatch => {
    dispatch({ type: ADD_RECENT_SIZES_TO_UTAG_DATA, payload: { recentSizes } });
  };
}

export function getFetchSize(state) {
  if (
    get(state, 'session.deviceType.isMobile', false) &&
    state.toggles.PLP_MOBILE_FETCH_SIZE &&
    !get(state, 'session.deviceType.isTablet', false)
  ) {
    return get(state, 'mobileConfig.pagination.fetchSize', null);
  }
  return null;
}

export function getPlpCategory(requestOptions = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(getProductList(requestOptions))
        .then(resolve)
        .catch(() => {
          dispatch({ type: REJECTED_PRODUCT_LIST });
          dispatch({ type: SET_CANONICAL_URL, canonicalUrl: '' });
          reject();
        });
    });
}

export function getPlpCategoryWithSpecificRecs(requestOptions = {}, MBOX_IDS) {
  return dispatch =>
    dispatch(getABTestAssignments(MBOX_IDS.join(','), true))
      .then(() => {
        dispatch(getPlpCategory(requestOptions));
      })
      .catch(() => {
        dispatch(getPlpCategory(requestOptions));
      });
}

export function getPlpCategoryWithTests(requestOptions = {}, TEST_IDS) {
  return (dispatch, getState) => {
    const testIdsForService = [];
    const state = getState();
    for (let i = 0; i < TEST_IDS.length; i++) {
      if (state.cookies[TEST_IDS[i].abTestCookieName]) {
        dispatch({
          type: SET_TEST_FROM_COOKIE,
          payload: {
            ...TEST_IDS[i],
            abTestCookieValue: state.cookies[TEST_IDS[i].abTestCookieName],
            abTestExperience: '',
          },
        });
      } else {
        testIdsForService.push(TEST_IDS[i]);
      }
    }
    if (testIdsForService.length > 0) {
      return dispatch(getABTests(testIdsForService))
        .then(() => {
          dispatch(getPlpCategory(requestOptions));
        })
        .catch(() => {
          dispatch(getPlpCategory(requestOptions));
        });
    } else {
      return dispatch(getPlpCategory(requestOptions));
    }
  };
}

export function getPlpOptions(requestOptions = {}) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(getProductList(requestOptions))
        .then(resolve)
        .catch(err => {
          if (err.type === NO_RESULTS_FOR_FILTER) {
            dispatch(
              openModal({
                type: 'ModalWithoutHeader',
                message: err.message,
                className: 'modal-without-header',
              }),
            );
          } else if (err.type === NO_RESULTS_FOR_FACET_FILTER) {
            dispatch(hideSpinner());
          } else if (!err.isHandled) {
            dispatch(openModal({ type: 'ProductListErrorModal' }));
          }
          reject();
        });
    });
}

export function getPageDefintionId(data) {
  switch (data.templateType) {
    case LSSTemplateType: {
      return LSSPageName;
    }
    case EditorialTemplateType: {
      return EditorialPageName;
    }
    case MagzineTemplateType: {
      return MagzinePageName;
    }
    default: {
      return data.templateType;
    }
  }
}
export function getUtagFiltersData(filterOptions) {
  const inStoreFilter = 'In Store';

  const renameKeyForUtagFilterAnalysis = key => {
    switch (key) {
      case 'level1':
        return 'Category';
      case 'level2':
        return 'Type';
      // case 'level3':
      //   return 'Subcategory';
      case 'level4':
        return 'Subtype';
      case 'level5':
        return 'Product Type';
      default:
        return key;
    }
  };

  const filterOptionsWithoutInstoreZipCode = {
    ...filterOptions,
    [inStoreFilter]: get(filterOptions, inStoreFilter, []).slice(0, 1),
  };

  return {
    filterSelection: flatten(
      reject(filterOptionsWithoutInstoreZipCode, isEmpty),
    ),
    filterType: flatMap(Object.keys(filterOptionsWithoutInstoreZipCode), key =>
      Array(filterOptionsWithoutInstoreZipCode[key].length).fill(
        renameKeyForUtagFilterAnalysis(key),
      ),
    ),
  };
}

const isFacetsExpereince = state => {
  return get(state, 'toggles.FACETED_LEFT_NAV', false);
};

export const isHybridFacetsOn = toggles => {
  return get(toggles, 'HYBRID_FACETS', false);
};

export function getProductList(requestOptions = {}) {
  return (dispatch, getState) => {
    dispatch(showSpinner());
    dispatch({ type: LOADING_PRODUCT_LIST });
    const state = getState();
    if (isFacetsExpereince(state) || isHybridFacetsOn(state.toggles)) {
      dispatch({ type: RESET_INSTORE_FILTER_ERROR });
    }
    const currentCategory = get(state, 'productListPage.currentCategory');
    const currentPage = get(state, 'productListPage.products.currentPage', 1);
    const abtTestOptArr = getOptAnalyticsFromState(state);
    const optAnalytic = reactCookie.load('_optanalytics');
    const optAnalyticsResult =
      abtTestOptArr.length > 0 ? abtTestOptArr : optAnalytic?.split(',');
    const {
      page = currentPage,
      categoryId = get(currentCategory, 'id', '').split('_')[0],
      parentCategoryId = '',
      siloCategoryId,
      sortBy = '',
      filterOptions,
      priorityProdId,
      profileId = get(state, 'utag.userSession.web_id', ''),
    } = requestOptions;
    const { user, session } = state;
    let deviceType;
    if (
      get(session, 'deviceType.isMobile', false) &&
      !get(session, 'deviceType.isTablet', false)
    ) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
        ENABLE_PROMO_PREVIEW: get(state, 'cookies.enablePromoPreview'),
        PLP_ONLY_X_LEFT: get(state.toggles, 'PLP_ONLY_X_LEFT', false),
        FLATTENED_PLP_RESPONSE_1: get(
          state.toggles,
          'FLATTENED_PLP_RESPONSE',
          false,
        ),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
      }),
      [X_ABTEST_INFO]: join(
        get(state, 'abTests.responses', []).map(
          i => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`,
        ),
        '~',
      ),
      [X_DEVICE_TYPE]: deviceType,
      ucid: get(user, 'ucid', ''),
      wid: get(state, 'utag.userSession.web_id', ''),
      'page-url': get(session, 'url', ''),
      pageurl: get(session, 'url', ''),
      zip: get(state, 'api.requestContext.akamaiEdgescape.zip', ''),
      abTestsOpt: optAnalyticsResult?.join(',') || {},
    };
    const seoToggle = get(state, 'toggles.SEO_BUCKET_ONE', false);
    const inStoreToggle = get(state, 'toggles.IN_STORE_FILTER', false);
    if (seoToggle) {
      if (inStoreToggle) {
        const params = new URLSearchParams(get(location, 'search', ''));
        params.delete('filterOptions');
        headers['seo_query'] = `?${params}`;
      } else {
        headers['seo_query'] = get(location, 'search', '');
      }
    }

    const nextURL = (window && window.location?.href) || '';
    const splitNextURL = nextURL?.split('?');
    const nextParams = splitNextURL[1]?.split('&');
    try {
      const vertexAiToggle = get(state, 'toggles.BG_VERTEX_AI_PLP', false);
      if (vertexAiToggle) {
        const defaultCustomerId = reactCookie.load('df_cid');
        const customerData = reactCookie.load('cstmr');
        headers['visitorid'] = !isEmpty(defaultCustomerId)
          ? defaultCustomerId
          : customerData?.customerId || '';
        headers['breadcrumbpath'] =
          get(state, 'utag.breadcrumbs.bread_crumb')?.join('>') || '';
        if (customerData?.isLoggedin) {
          headers['userId'] = customerData?.customerId || '';
        }
      }
    } catch (e) {
      console.error(
        `Error occurred while setting visitorId and breadcrumbpath when vertex toggle enabled. Error: ${e}`,
      );
    }

    const requestApi = httpWithLogging(
      state,
      get(state, 'apiTimeouts.PRODUCT_LIST_API_TIMEOUT', 8000),
    );

    function ABTestReponse(mboxId) {
      const abTestReponse = get(state, 'abTests.responses', null);
      const pcsMboxReponse = result(
        find(abTestReponse, obj => {
          return obj.mboxId === mboxId;
        }),
        'assignment',
        null,
      );
      let experienceId = null;
      if (pcsMboxReponse !== null) {
        experienceId = get(pcsMboxReponse, 'value.experienceId', null);
      }
      return experienceId;
    }

    function ABTestResult(testId) {
      const abTestResult = get(state, 'abTests.abTestResults', null);
      if (abTestResult) {
        return abTestResult[testId]
          ? get(abTestResult[testId], 'abTestCookieValue', null)
          : null;
      }
      return null;
    }

    function getProductListApiURL(
      categoryId,
      parentCategoryId,
      siloCategoryId,
      page,
      sortBy,
      filterOptions,
      personalizedPromos,
      priorityProdId,
      profileId,
    ) {
      const sortByParams = sortBy ? `&sortBy=${sortBy}` : '';
      const personalizedPromosParams =
        personalizedPromos && personalizedPromos.length
          ? `&personalizedPromos=${personalizedPromos}`
          : '';
      const priorityProdIdParam =
        priorityProdId && priorityProdId.length
          ? `&priorityProdId=${priorityProdId}`
          : '';
      let abTestPCSVendor;
      let abTestFacets;
      if (get(state, 'toggles.USE_INTERNAL_ABTEST_SERVICE', false)) {
        abTestPCSVendor = ABTestResult(PLP_PCS_RECOMMEDATION_TEST);
        abTestFacets = ABTestResult(FACETED_LEFT_NAV_TEST);
      } else {
        abTestPCSVendor = ABTestReponse(PLP_PCS_RECOMMEDATION_MBOX_ID);
        abTestFacets = ABTestReponse(FACETED_LEFT_NAV_MBOX_ID);
      }

      function isCategoryNewAspectRatio(state) {
        return get(state, 'templates.templateDetails.newAspectRatio', false);
      }

      const defaultParams = {
        categoryId,
        parentCategoryId,
        siloCategoryId,
        page,
        profileId,
      };

      if (!inStoreToggle) {
        defaultParams['filterOptions'] = filterOptions;
      }

      const queryBuilder = (
        abTestFacets,
        abTestPCSVendor,
        fetchSize,
        newAspectRatio,
      ) => {
        let params = {};
        if (get(state, 'toggles.BYPASS_ABTEST_PCS', false)) {
          if (
            get(
              state,
              'templates.templateDetails.templateAttributes.dynamicContent.categoryFlags.excludeFromPCS',
              false,
            )
          ) {
            params = { ...params };
          } else {
            params = { ...params, abTestPCSVendor: 'EVG' };
          }
        } else {
          if (abTestPCSVendor !== null) {
            params = { ...params, abTestPCSVendor };
          }
          if (abTestFacets !== null) {
            params = { ...params, abTestFacets };
          }
        }

        if (fetchSize != null) {
          params = { ...params, fetchSize };
        }
        if (newAspectRatio) {
          params = { ...params, newAspectRatio };
        }
        return params;
      };

      const generateURL = () => {
        const fetchSize = getFetchSize(state);
        const newAspectRatio = isCategoryNewAspectRatio(state);
        const additionalParam = queryBuilder(
          abTestFacets,
          abTestPCSVendor,
          fetchSize,
          newAspectRatio,
        );
        return `${NMConfig.API_PRODUCT_LIST}?${stringify({
          ...defaultParams,
          ...additionalParam,
        })}${sortByParams}${personalizedPromosParams}${priorityProdIdParam}`;
      };

      return generateURL();
    }

    function shouldShowNoFavoriteMessage(products = []) {
      const isPageOneProducts =
        (isEmpty(page) || page === '1') &&
        (products.length === 0 || !products[0].fav);
      return (
        state.productListPage.selectedSortBy !== MY_FAVORITES &&
        sortBy === MY_FAVORITES &&
        isPageOneProducts
      );
    }

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(
        state,
        'productListPage.products.selectedSortOption',
      );
      return (
        !isEmpty(sortBy) &&
        !isEmpty(previousSortOption) &&
        previousSortOption !== sortBy
      );
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(
        state,
        'productListPage.selectedFilterOptions',
      );
      return (
        !isEmpty(selectedFilters) &&
        !isEmpty(previousFilterOptions) &&
        !isEqual(previousFilterOptions, selectedFilters)
      );
    }

    /* function shouldShowInStoreFilterMessage(products) {
      return (
        isEmpty(products) &&
        !isEmpty(filterOptions) &&
        includes(filterOptions, IN_STORE) &&
        !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE])
      );
    } */

    function getPlpType(displayAsGroupsFlag) {
      return displayAsGroupsFlag ? 'group' : 'non-group';
    }

    function facetDisplaybleText(seoFacetsMap, searchKey) {
      const facetObjs = seoFacetsMap.filter(obj => {
        const facetObj = Object.keys(obj)[0] !== '' && Object.values(obj)[0];
        return (
          facetObj &&
          Object.keys(facetObj).some(key => {
            return facetObj[key] && facetObj[key].includes(searchKey);
          })
        );
      });
      return !isEmpty(facetObjs[0]) ? Object.keys(facetObjs[0]) : '';
    }

    function getOptAnalyticsFromState(state) {
      const abTestsOptArr = [];
      if (state.abTestsOpt) {
        forEach(state.abTestsOpt, item => {
          if (item?.analytics) {
            abTestsOptArr.push(item.analytics);
          }
        });
      }
      return abTestsOptArr;
    }

    return new Promise((resolve, reject) => {
      const selectedFilters = isEmpty(filterOptions)
        ? {}
        : JSON.parse(filterOptions);
      const { session } = getState();
      const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
      let getProductListUrl = getProductListApiURL(
        categoryId,
        parentCategoryId,
        siloCategoryId,
        page,
        sortBy,
        filterOptions,
        promos,
        priorityProdId,
        profileId,
      );
      const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
      if (internationalToggle) {
        const currencyCode = get(state, 'locale.currencyCode', 'USD');
        if (currencyCode !== 'USD') {
          let currencyQuery = `?currency=${currencyCode}`;
          if (
            getProductListUrl.indexOf('?') > -1 ||
            getProductListUrl.indexOf('&') > -1
          ) {
            currencyQuery = `&currency=${currencyCode}`;
          }
          getProductListUrl = `${getProductListUrl}${currencyQuery}`;
        }
      }
      requestApi
        .get(getProductListUrl, { headers })
        .then(successResponse => {
          const facetsExpereince =
            isFacetsExpereince(state) || isHybridFacetsOn(state.toggles);
          /* if (shouldShowInStoreFilterMessage(successResponse.data.products)) {
            if (facetsExpereince) {
              dispatch({ type: SET_INSTORE_FILTER_ERROR });
              return Promise.reject({ type: NO_RESULTS_FOR_FACET_FILTER });
            } else {
              return Promise.reject({
                type: NO_RESULTS_FOR_FILTER,
                message: INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
              });
            }
          }  else if (shouldShowFilterMessage(successResponse.data.products)) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FILTER,
              message: PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE,
            });
          } */

          const isFlattenedPlpResponse = get(
            state,
            'toggles.FLATTENED_PLP_RESPONSE',
            false,
          );
          const defaultSortoption = {
            FEATURED: 'Featured',
            isDefault: true,
          };
          // const { applicableFilterType } = get(
          //   successResponse.data,
          //   'applicableFilters',
          //   [],
          // );
          const applicableFilterType =
            successResponse.data.applicableFilters || [];
          if (
            successResponse.data.sortOptions &&
            successResponse.data.sortOptions.length === 0
          ) {
            successResponse.data.sortOptions.push(defaultSortoption);
          }
          dispatch({
            type: RESOLVED_PRODUCT_LIST,
            payload: {
              ...successResponse.data,
              facetsExpereince,
              page,
              isFlattenedPlpResponse,
            },
          });

          dispatch(hideSpinner());

          dispatch({
            type: types.SET_FILTER_OPTIONS,
            selectedFilterOptions: isEmpty(filterOptions)
              ? 'No filter options selected'
              : JSON.parse(filterOptions),
          });

          // dispatch({
          //   type: RESOLVED_PRODUCT_LIST,
          //   payload: { ...successResponse.data, facetsExpereince, page }
          // });

          // dispatch(hideSpinner());

          const applicableFilters = get(
            successResponse.data,
            'applicableFilters',
            [],
          );
          const seoFacets = get(successResponse, 'data.seoFacets', []);
          let canonicalUrl = successResponse.data.seo.canonicalUrl
            ? successResponse.data.seo.canonicalUrl
            : '';
          if (!isEmpty(seoFacets)) {
            const lowerCaseFacets = seoFacets.map(facet => facet.toLowerCase());
            dispatch({
              type: SET_SEO_FACETS_ORDER,
              payload: lowerCaseFacets,
            });
            const firstKeyFormatted = replaceSpaceWithHyphenAndEncode(
              lowerCaseFacets[0],
            );
            const firstFilter = applicableFilters.find(
              filter => filter.displayText.toLowerCase() === lowerCaseFacets[0],
            );
            const firstValue = {
              displayText: seoFacets[0],
              filterKey: firstFilter?.filterKey,
            };
            const secondKeyFormatted = replaceSpaceWithHyphenAndEncode(
              lowerCaseFacets[1],
            );
            const secondFilter = applicableFilters.find(
              filter => filter.displayText.toLowerCase() === lowerCaseFacets[1],
            );
            const secondValue = {
              displayText: seoFacets[1],
              filterKey: secondFilter?.filterKey,
            };
            const seoFacetsMap = [
              { [firstKeyFormatted]: firstValue },
              { [secondKeyFormatted]: secondValue },
            ];
            dispatch({
              type: SET_SEO_FACETS_MAP,
              payload: seoFacetsMap,
            });
            const appliedFacet = [];

            if (seoToggle && !isEmpty(filterOptions)) {
              const updatedFilters = JSON.parse(filterOptions);
              let canonicalPathValue = '';
              const filterKeyList = [];
              seoFacetsMap.forEach(facet => {
                Object.keys(facet).forEach(seoKey => {
                  const filterKey = applicableFilters.find(
                    filter => filter.displayText === facet[seoKey]?.displayText,
                  )?.filterKey;
                  if (filterKey) filterKeyList.push(filterKey);
                });
              });
              Object.keys(updatedFilters).forEach(key => {
                if (
                  updatedFilters[key].length === 1 &&
                  filterKeyList.includes(key)
                ) {
                  const selectedFacetText = facetDisplaybleText(
                    seoFacetsMap,
                    key,
                  );
                  appliedFacet.push(
                    `${selectedFacetText}=${updatedFilters[key]}`,
                  );
                }
              });
              if (appliedFacet.length > 0) {
                canonicalPathValue = appliedFacet.join('&');
                canonicalUrl += `?${canonicalPathValue}`;
              }
            }
            // finding current page from nextParams
            const pageParams = nextParams.filter(param =>
              param.includes('page='),
            );
            const isFirstPage =
              !pageParams.length || pageParams[0] === 'page=1';
            if (!isFirstPage && pageParams.length) {
              const separator = canonicalUrl.includes('?') ? '&' : '?';
              canonicalUrl += `${separator}${pageParams.join('&')}`;
            }
            // Removing trailing ampersand if present
            if (canonicalUrl.endsWith('&')) {
              canonicalUrl = canonicalUrl.slice(0, -1);
            }

            // Ensuring no trailing question mark is present
            if (canonicalUrl.endsWith('?')) {
              canonicalUrl = canonicalUrl.slice(0, -1);
            }
          }

          dispatch({
            type: SET_CANONICAL_URL,
            canonicalUrl,
          });
          dispatch({
            type: types.SET_SORT_BY,
            sortBy: successResponse.data.selectedSortOption,
          });
          dispatch({ type: VALID_CATEGORY });
          let utagPayload = {};
          if (isFacetsExpereince(state) || isHybridFacetsOn(state.toggles)) {
            utagPayload = {
              facetOptions: getUtagFiltersData(selectedFilters),
              previousViewFacetSubmit: isFilterOptionsChanged(selectedFilters),
              facetFlag: true,
            };
          } else {
            utagPayload = {
              filterOptions: getUtagFiltersData(selectedFilters),
              previousViewFilterSubmit: isFilterOptionsChanged(selectedFilters),
              applicableFilterType,
            };
          }
          dispatch({
            type: SET_PRODUCT_LIST_DATA_TO_UTAG,
            payload: {
              total: successResponse.data.total,
              templateType: successResponse.data.templateType,
              category: currentCategory,
              cmosCatalog: map(successResponse.data.products, 'cmosCatalog'),
              cmosItem: map(successResponse.data.products, 'cmosItem'),
              sortBy: successResponse.data.selectedSortOption,
              page,
              previousViewSortSubmit: isSortOptionChanged(sortBy),
              facetEligible: true,
              plpType: getPlpType(successResponse.data.displayAsGroups),
              pcsEnabled: '',
              ...utagPayload,
              product_id: map(successResponse.data.products, 'id'),
              ...updatePlpGrsUtagData(successResponse.data),
            },
          });
          if (shouldShowNoFavoriteMessage(successResponse.data.products)) {
            dispatch(
              openModal({
                type: 'ModalWithoutHeader',
                message: FAVORITES_NOT_AVAILABLE_MESSAGE,
              }),
            );
          }
          return resolve();
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            dispatch({ type: INVALID_CATEGORY });
          }
          dispatch(hideSpinner());
          if (err.response && err.response.status === 424) {
            logger.error(
              `API timed out while waiting to search for products for categoryId: ${categoryId}, HTTP Status: ${err.response.status}`,
            );
          }
          reject(err);
        });
    });
  };
}

export function getProductListMetaData(productIds, customHeaders) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const requestApi = httpWithLogging(
      state,
      get(state, 'apiTimeouts.PRODUCT_LIST_METADATA_API_TIMEOUT', 20000),
    );
    const combinedHeaders = {
      ...customHeaders,
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    dispatch({ type: LOADING_PRODUCT_LIST_METADATA });
    return new Promise(resolve => {
      requestApi
        .post(`${NMConfig.API_PRODUCT_LIST_METADATA}`, productIds, {
          headers: combinedHeaders,
        })
        .then(successResponse => {
          dispatch({
            type: RESOLVED_PRODUCT_LIST_METADATA,
            payload: successResponse.data,
          });
          resolve();
        })
        .catch(() => {});
    });
  };
}

export function getCategoryNoResultsContent() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    return requestApi
      .get(NMConfig.API_REFRESHABLE_CONTENT, {
        params: {
          refreshablePath: '/category/search/r_dt_noresults.html',
        },
      })
      .then(response => {
        dispatch({
          type: RESOLVED_CATEGORY_NO_RESULTS_CONTENT,
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: REJECTED_CATEGORY_NO_RESULTS_CONTENT });
      });
  };
}

export function addFavorite(
  productId,
  cmosCatalogId,
  cmosItem,
  pimStyle,
  isFavoritesPage,
  isDisplayAsGroups,
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .put(`${NMConfig.API_ADD_FAVORITE}?favProdIds=${productId}`)
      .then(successResponse => {
        dispatch({
          type: RESOLVED_FAVORITE,
          data: successResponse.data,
        });
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            cmosItem,
            pimStyle,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
      })
      .catch(() => {});
  };
}
export function removeFavorite(
  productId,
  cmosCatalogId,
  cmosItem,
  pimStyle,
  isFavoritesPage,
  isDisplayAsGroups,
) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const requestApi = httpWithLogging(state);
    const headers = {
      UCID: get(user, 'ucid', 'user001'),
      'X-Api-Key': 'jdRt6TejIC3dQJTUbpqwY9AwNPI5NBGy24OThDwk',
    };
    return requestApi
      .delete(`${NMConfig.API_REMOVE_FAVORITE}?favProdIds=${productId}`, {
        headers,
      })
      .then(successResponse => {
        dispatch({
          type: RESOLVED_FAVORITE,
          data: successResponse.data,
        });
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            cmosItem,
            pimStyle,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
      })
      .catch(() => {});
  };
}

export function showQLModalWindow(
  productId,
  productUrl,
  navPath,
  isDisplayAsGroups,
) {
  const data = {
    productId,
    productUrl,
    navPath,
  };

  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));

    let requestURI =
      promos && promos.length
        ? `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}?personalizedPromos=${promos}`
        : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    const requestApi = httpWithLogging(state);
    return requestApi
      .get(requestURI, { headers })
      .then(successResponse => {
        dispatch({
          type: RESOLVED_QUICK_LOOK_PRODUCT,
          payload: {
            ...successResponse.data,
            displayAsGroups: isDisplayAsGroups,
          },
        });
        const product = successResponse.data || {};
        if (product.grp) {
          dispatch({ type: 'RESOLVED_GROUP', payload: successResponse.data });
        }
        dispatch(
          openModal({
            type: 'QLProductSummary',
            props: product,
            className: 'ql-product-summary',
          }),
        );
      })
      .catch(() => {
        dispatch(openModal({ type: 'ProductListErrorModal' }));
      });
  };
}

export function sourceQuickLook(isEnabled) {
  return dispatch => dispatch({ type: SOURCE_QUICK_LOOK, payload: isEnabled });
}

export function resetQLProduct() {
  return dispatch => dispatch({ type: RESET_QUICK_LOOK_PRODUCT });
}

export function updateSwatchesValue(swatchIndex, productId) {
  return dispatch =>
    dispatch({
      type: UPDATE_SWATCH_VALUE,
      payload: {
        swatchIndex,
        productId,
      },
    });
}

export function initializeSwatchValues() {
  return dispatch => dispatch({ type: INIT_SWATCH_VALUES });
}

export const selectedViewBy = dispatch => {
  return viewType =>
    dispatch({
      type: GET_VIEW_BY,
      viewType,
    });
};

export function handleMobileFilterSortButtonDisplay(flag) {
  return dispatch =>
    dispatch({
      type: SET_MOBILE_FILTER_SORT_BUTTON_FLAG,
      flag,
    });
}

export function getPlpRelatedCategory() {
  return (dispatch, state) => {
    dispatch({ type: LOADING_PLP_RELATED_CATS });
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    const location = window.location.origin + window.location.pathname;
    return requestApi
      .get(`${NMConfig.PLP_RELATED_CATS}?url=${location}`)
      .then(({ data }) => {
        const relatedData = data?.related_links || [];
        dispatch({
          type: RESOLVED_PLP_RELATED_CATS,
          payload: relatedData,
        });
      })
      .catch(err => {
        dispatch({ type: REJECTED_PLP_RELATED_CATS });
        logger.error(`Could not load Related categories , HTTP Status: ${err}`);
      });
  };
}

export function getPlpBroughtCategory() {
  return (dispatch, state) => {
    dispatch({ type: LOADING_PLP_BROUGHT_CATS });
    const requestApi = httpWithLoggingWithoutCustomHeaders(state, 8000, true);
    const location = window.location.origin + window.location.pathname;
    return requestApi
      .get(`${NMConfig.PLP_BROUGHT_CATS}?url=${location}`)
      .then(({ data }) => {
        const relatedData = data?.related_links || [];
        const broughtList = relatedData.map(item => {
          const stylyzeImgUrl = item?.image_url || '';
          const canonicalUrl = item?.url || '';
          const designerName = item?.brand || '';
          const broughtPrice = item?.price || '';
          let price = '';
          if (item?.original_price) {
            price = {
              currencyCode: '$',
              retailPrice: item?.original_price,
              adornments: [
                {
                  label: 'Original',
                  price: item?.original_price,
                },
                {
                  label: 'NOW',
                  price: item?.price,
                },
              ],
            };
          } else {
            price = { currencyCode: '$', retailPrice: item?.price };
          }
          item.price = price;
          return {
            ...item,
            stylyzeImgUrl,
            canonicalUrl,
            designerName,
            broughtPrice,
          };
        });
        dispatch({
          type: RESOLVED_PLP_BROUGHT_CATS,
          payload: broughtList,
        });
      })
      .catch(err => {
        dispatch({ type: REJECTED_PLP_BROUGHT_CATS });
        logger.error(`Could not load Related Products , HTTP Status: ${err}`);
      });
  };
}
